import supplierContactServices from '../../../../services/provider/supplier-contacts'
import recallService from '../../../../services/provider/recall'
export default {
    data() {
        return {
            item: {
                organization_id: null,
                supplier_contactid: null,
                first_name: null,
                last_name: null,
                email: null,
                title: null,
                phone_number: null,
                supplierName: ''
            },
            formType: 'INSERT',
            autocompleteItems: [],
        }
    },
    computed: {
        form() {
            if(this.formType == 'INSERT'){
                return {
                    title: 'New Supplier Contact',
                    isDisable: false
                }
            }
            if(this.formType == 'UPDATE'){
                return {
                    title: 'Update Supplier Contact',
                    isDisable: true
                }
            }
        }
    },
    methods: {
        fetchsuppliernameList()
        {
            supplierContactServices.GetSupplierNameList().then((res) => {
                for (let i = 0; i < res.data.d.length; i++) {
                    this.autocompleteItems.push(res.data.d[i].text)
                }
            })
        },
        forceRerenderInsert(organization_id) {
            this.fetchsuppliernameList()
            this.formType = 'INSERT'
            this.item.organization_id = this.$store.state.orgId
            this.$refs.modal.show()
        },
        forceRerenderUpdate(payload) {
            this.fetchsuppliernameList()
            this.getSupplierContact(payload.supplier_contactid)
            this.item.supplierName=payload.supplierName
            this.supplier_contactid=payload.supplier_contactid
            this.formType = 'UPDATE'
            this.$refs.modal.show()
        },
        async save() {
            var isValid = await this.$refs['observer'].validate()
            if(isValid && this.formType == 'INSERT') {
                this.saveSupplierContact()
            }
            if(isValid && this.formType == 'UPDATE'){
                this.saveSupplierContact()
            }
        },
        saveSupplierContact() {
            supplierContactServices.createSupplierContact(this.item).then(resp=>{
                if(!resp.error){
                    if(this.formType=='INSERT')
                    {
                        this.$emit('onCompleted', 'INSERT')
                    }
                    else{
                        this.$emit('onCompleted', 'UPDATE')
                    }
                    this.$refs.modal.hide()
                }
            })
        },
        getSupplierContact(id) {
            supplierContactServices.getSupplierContact(id).then(resp=>{
                if(!resp.error){
                    this.item = resp.data.d
                    this.acceptNumber()
                }
            })
        },
        acceptNumber() {
            var x = this.item.phone_number.replace('+1', '').replace(/^0+/, '').replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
            this.item.phone_number =  (x[1] ? x[1] + '-' : '') + x[2] + (x[3] ? '-' + x[3] : '')
        },
    }
}