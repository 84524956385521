import {http, param} from '../../utils/http'
import conf from '../../config'

export const API_ROOT = `${conf.API_URL}`

const supplierContactServices = {
    findSupplierContacts(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? payload.search.trim() : '' },
        ]
        return http.get(`${API_ROOT}/suppliercontact${param(arr)}`).then(resp => {
            return resp
        })
    },
    deleteSupplierContacts(id) {
        return http.delete(`${API_ROOT}/suppliercontact/${id}`).then(resp => {
            return resp
        })
    },
    getSupplierContact(id) {
        return http.get(`${API_ROOT}/suppliercontact/${id}`).then(resp => {
            return resp
        })
    },
    getAutoNotifySetting() {
        return http.get(`${API_ROOT}/suppliercontact/AutoNotify`).then(resp => {
            return resp
        })
    },
    updateSupplierContact(payload) {
        return http.put(`${API_ROOT}/suppliercontact/${payload.id}`, payload).then(resp => {
            return resp
        })
    },
    createSupplierContact(payload) {
        return http.post(`${API_ROOT}/suppliercontact`, payload).then(resp => {
            return resp
        })
    },
    UpdateAutoNotifySetting(autoNotify)
    {
        return http.post(`${API_ROOT}/suppliercontact/update-autonotify/${autoNotify}`).then(resp => {
            return resp
        })
    },
    uploadFile (formData) {
        return http.post(`${API_ROOT}/suppliercontact/upload-suppliercontact-file`, formData).then(resp => {
            return resp
        })
    },
    SendSupplierContactEmail(suppliercontactlst)
    {
        return http.post(`${API_ROOT}/suppliercontact/send-email/`, suppliercontactlst).then(resp => {
            return resp
        })
    },
    GetSupplierNameList () {
        return http.get(`${API_ROOT}/suppliercontact/supplierNameList`).then(resp => {
            return resp
        })
    },
}

export default supplierContactServices
